:root {
  font-size: 16px;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: hsla(0, 0%, 88%, 0.5);
  --color-dark: #202124;
  --color-dark-2: #303136;
  --color-yellow: #ffed9b;
  --color-orange: #ffb68d;
  --color-green: #b3ffcf;
  --color-blue: #91d3ff;
  --color-purple: #dba2ff;
  --color-pink: #ffaae8;
  --color-red: #ff8a8a;
  --background-body: var(--color-white);
  --background-surface: var(--color-gray);
  --background-surface-secondary: var(--color-gray);
  --color-surface-secondary: var(--color-white);
  --color-bg-white: #fff;
  --color-text-black: #000;
  --background-linear-gradient: linear-gradient(
    90deg,
    var(--color-blue) 5%,
    var(--color-green),
    var(--color-yellow),
    var(--color-orange),
    var(--color-red),
    var(--color-pink),
    var(--color-purple) 120%
  );
  --color-white-translucent: rgba(255, 255, 255, 0.5);
  --color-black-translucent: rgba(0, 0, 0, 0.05);
  --color-black-translucent-50: rgba(0, 0, 0, 0.5);
  --box-shadow:
    0 1.8px 3.8px 0 rgb(0 0 0 / 7%), 0 0.3px 0.9px 0 rgb(0 0 0 / 5%);
  --shadow-2: 0px 15px 50px 0px rgba(27, 32, 50, 0.1);
  --opacity: 0.55;
  --svg-filter: none;
  --font-size: 1rem;
  --font-size-small: 0.8rem;
  --font-size-big: 1.15rem;
  --font-size-3: 1.5rem;
  --font-size-2: 2.5rem;
  --font-size-1: 3.5rem;
  --radius: 1rem;
  --radius-small: 0.75rem;
  --radius-big: 1.75rem;
  --radius-full: 75rem;
  --size-small: 2.625rem;
  --size-large: 3.25rem;
  --size-big: 3rem;
  --container-sidebar: 15rem;
  --max-size-container: 1024px;
  --max-size-container-2: 780px;
  --max-size-container-3: 820px;
  --transition-hover:
    hover 0.2s, background 0.2s, opacity 0.2s, box-shadow 0.2s;
  --transition: all 200ms;
}

html[data-theme='dark'] {
  --background-body: var(--color-dark);
  --background-surface: var(--color-dark-2);
  --background-surface-secondary: #18191b;
  --color-surface-secondary: var(--color-dark-2);
  --color-text-black: #fff;
  --svg-filter: invert(1);
  --color-bg-white: var(--color-dark-2);
  --shadow-2: 0px 15px 50px 0px rgba(198, 208, 244, 0.1);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  outline-color: var(--color-black);
  background: var(--background-body);
}
main {
  text-align: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a,
button,
h1,
h2,
h3,
p,
textarea,
input,
select,
option {
  font-family: inherit;
  letter-spacing: -0.025em;
  outline-color: var(--color-text-black);
}

a,
button,
h1,
h2,
h3,
input,
select,
option {
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

p {
  font-size: var(--font-size);
}
h1 {
  font-size: var(--font-size-1);
  line-height: 1.2;
}
h2 {
  font-size: 2rem;
  line-height: 1.2;
}
h3 {
  font-size: 1.5rem;
}

article {
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
  color: var(--color-text-black);
}
article h2 {
  margin: 4rem 0 1.5rem;
}
article ul {
  list-style: disc;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
article li {
  margin-bottom: 1rem;
}
article hr {
  margin-top: 2rem;
  border: 0;
}

/* Colors Animation */
.backgroundFindto {
  background: var(--color-findto);
}
.fillFindto {
  fill: var(--color-text-black);
}

.colors {
  animation: colorsAnimation 60s ease alternate infinite;
}
@keyframes colorsAnimation {
  0% {
    background: var(--color-green);
  }
  25% {
    background: var(--color-yellow);
  }
  50% {
    background: var(--color-red);
  }
  75% {
    background: var(--color-purple);
  }
  100% {
    background: var(--color-blue);
  }
}

.blur {
  position: absolute;
  width: 539px;
  height: 330px;
  left: 0px;
  top: 93px;
  background: rgba(255, 255, 255, 0.01);
  -webkit-backdrop-filter: blur(77.2327px);
          backdrop-filter: blur(77.2327px);
  border-radius: 37px;
}
::selection {
  color: var(--color-bg-white);
  background: var(--color-text-black);
}

::placeholder {
  color: var(--color-text-black);
  opacity: var(--opacity);
}
/* IE 10-11 */
:-ms-input-placeholder {
  color: var(--color-text-black);
  opacity: var(--opacity);
}
/* MS Edge (legacy) */
::-ms-input-placeholder {
  color: var(--color-text-black);
  opacity: var(--opacity);
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-size-container);
  display: inline-flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1rem 3rem;
}

.sidebar {
  margin-left: var(--container-sidebar);
}
.sidebarClosed {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }

  .sidebar {
    margin-left: 0;
  }
}

@font-face {
font-family: '__fontFamily_290c25';
src: url(/_next/static/media/d1f885f0193405e4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__fontFamily_290c25';
src: url(/_next/static/media/819bb5839ca6d86e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__fontFamily_Fallback_290c25';src: local("Arial");ascent-override: 87.08%;descent-override: 19.74%;line-gap-override: 0.00%;size-adjust: 119.55%
}.__className_290c25 {font-family: '__fontFamily_290c25', '__fontFamily_Fallback_290c25'
}

